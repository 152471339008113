.MuiAccordionSummary-content {
    margin: 0;
}

.Mui-expanded {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
}

.MuiAccordionDetails-root {
    padding: 0 32px;
}

.MuiAccordion-root {
    position: relative;
}