body {
  margin: 0;
  font-weight: 400;
  font-family: 'Inter', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: none !important;
  min-width: 1000px;
}

html {
  min-height: 100%;
  background-color: #F0F0F0;
}

strong, b, strong *, b *
{
  font-weight: 600;
}
em, i, em *, i *
{
  font-style: italic;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#main-preview-render img {
  width: 400px;
  height: auto;
}

#secondary-preview-render img {
  width: 400px;
  height: auto;
}